body {
  
  background-image: url('images/img_tipisindios_apache.jpg');
}
.padre{
  display: flex;
  flex-direction: column;
  
}
.logo{
  display: flex;
  justify-content: center;
}
.child{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 100%;
}
.container {
  display: flex;
  justify-self: center;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-direction: column;
}

.clientDiv{
  display: flex;
  justify-content: center;
  align-content: center;
  
}

.selector{
  width: 25%;
  border-radius: 25px;
  height: 40px;
  font-family: "Montserrat", sans-serif ;
  font-size: 20px;
  margin-top: 4%;
  box-shadow: 5px 5px 10px black;

}

.textClient{
  font-size: 25px;
  margin-bottom: 10%;
  margin-right: 5%;
  color: white;
}

.upfile {
  color: white;
  height: 60px;
  width: 300px;
  margin: auto;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(73, 66, 143);
  border-radius: 25px;
  font-family: "Montserrat", sans-serif ;
  box-shadow: 5px 5px 10px black;
}

.upfile:active{
  width: 280;
  height: 50;
}

.subfile:hover {
  background-color: rgb(151, 136, 185);
}
.upfile:hover {
  background-color: rgb(151, 136, 185);
}

#subir{
  display: none;
}

.upload-box{
  font-size: 20px;
  background: white;
  border-radius: 25px;
  box-shadow: 5px 5px 10px black;
  height: 60px;
  width: 450px;
  outline: none;
  font-family: "Montserrat", sans-serif ;
  margin-bottom: 20%;

}

::-webkit-file-upload-button{
  height: 60px;
  color: white;
  background: rgb(73, 66, 143);
  padding: 20px;
  border: none;
  border-radius: 25px;
  box-shadow: 1px 0 1px 1px #6b4559;

}

::-webkit-file-upload-button:hover{
  background: rgb(151, 136, 185);

}

#txtb{
 
  font-family: "Montserrat", sans-serif ;
  color: white;
  
}

#tic{
  width: 280px;
  height: 290px;
 
}

#err{
  width: 315px;
  height: 320px;
  
}

#txta{
  font-family: "Montserrat", sans-serif ;
  color: white;
}

.backErr{
  width: 180px;
  height: 60px;
  font-size: 20px;
  background-color: rgb(73, 66, 143);
  color: white;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif ;
  box-shadow: 5px 5px 10px black;
  margin-top: 1%;
}

.backOk{
 
  width: 180px;
  height: 60px;
  font-size: 20px;
  background-color: rgb(73, 66, 143);
  color: white;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif ;
  box-shadow: 5px 5px 10px black;
  margin-top: 1%;
}

a:link, a:visited, a:active {
  text-decoration: none;
  color: white;
}

.backErr:hover{
  background: rgb(151, 136, 185);
}

.backOk:hover{
  background: rgb(151, 136, 185);
}

.wait{
  position: absolute;
  top: 40%;
  bottom: 0;
  left: 44.9%;
  right: 0;
}


#txtc{
  color: white;
  
  font-family: "Montserrat", sans-serif ;
}

#btnBack{
  display: none;
}

#logoApache{
  width: 500px;
  height: 180px;
}

.okFather{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.okChild{
  display: flex;
  justify-content: space-evenly;
}